import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { insuranceInformationCopy } from '../../cpapSuppliesTracker/constants'
import { listWhite } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'
import { useInsuranceModalRemoteStore } from 'stores/modalStore'

type InsuranceInformationStepProps = {
    stepStatus: boolean;
    isCurrentStep?: boolean;
 }

function InsuranceInformationStep({ stepStatus, isCurrentStep } : InsuranceInformationStepProps ) : JSX.Element {
  const { setIsRemoteModalOpen } = useInsuranceModalRemoteStore()

  const handleStepAction = () => {
    setIsRemoteModalOpen( true )
  }

  return (
    <div className="flex">
      <TrackerStepIcon imageSrc={listWhite} stepStatus={stepStatus} isCurrentStep={isCurrentStep} />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={{
          ...insuranceInformationCopy,
          onStepAction: handleStepAction
        }}
      />
    </div>
  )
}

export default InsuranceInformationStep