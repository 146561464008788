/* eslint-disable object-curly-newline */
import * as React from 'react'
import { Formatters, TextInput, Validators } from "components/forms/components"
import { useAddressStore } from '../state'
import LoadingSpinner from 'components/LoadingSpinner'
import useFetch from 'hooks/useFetch'
import useValidate from 'hooks/useValidate'
import { VerifyAddressResponse } from '../types'
import { serializeToOriginalAddress, serializeVerificationFlags, serializeFromVerifiedAddress} from '../serializers'
import { TypeAheadTextInput } from '../components'
import { useUpdateAddress } from '../hooks/useUpdateAddress'
import StateDropdownInput from 'components/forms/components/StateDropdownInput'
import { regexErrors } from 'components/forms/constants'

type EditAddressProps = {
  shouldSkipValidation: boolean;
  shouldSaveOnSubmit: boolean;
  onSuccessfulConfirm?: () => void;
}

export default function EditAddress({shouldSkipValidation, shouldSaveOnSubmit, onSuccessfulConfirm } : EditAddressProps ) : JSX.Element {
  const [ address, setAddress ] = useAddressStore( state => [ state.address, state.setAddress ] )

  const refs = {
    firstName: React.useRef<HTMLInputElement>( null ),
    lastName: React.useRef<HTMLInputElement>( null ),
    street: React.useRef<HTMLInputElement>( null ),
    street2: React.useRef<HTMLInputElement>( null ),
    city: React.useRef<HTMLInputElement>( null ),
    state: React.useRef<HTMLSelectElement>( null ),
    zipCode: React.useRef<HTMLInputElement>( null )
  } as Record<string, React.RefObject<HTMLInputElement | HTMLSelectElement>>

  const { evaluateErrorsOnChange, // Runs validation behind the scenes with onChange event
    evaluateErrorsOnSubmit, // Runs total validation and focuses on errored inputs with onSubmit event
    evaluateErrorsOnBlur, // Runs validation behind the scenes with onBlur event
    formErrors, // Object of errors for each field,
    setFormError, // Function to set a specific error for state dropdown
    shouldHideErrors,
    errorsPresent // Boolean that returns true if there are errors
  } = useValidate( refs )

  const { setCurrentView, setVerifiedAddress, setVerificationFlags, setSuggestionHighlights, setAddressWebConfirmed, setAddressPatientConfirmed} = useAddressStore()

  const [ isLoading, setIsLoading ] = React.useState<boolean>( false )
  const [ submitError, setSubmitError ] = React.useState<string>( `` )

  const {
    lazyFetch : submitVerifyAddress,
    isLoading : submitLoading
  } = useFetch<VerifyAddressResponse>( `${process.env.REACT_APP_VALIDATION_URL}/address`, {
    method: `POST`
  })

  const { updatePatientAddress, isUpdatingPatientAddress } = useUpdateAddress()

  const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    // Evaluate errors on change
    evaluateErrorsOnChange( event )

    const { name, value } = event.currentTarget

    // There is a special case for state, we need to revalidate the state on dropdown selection
    if ( name === `state` ) {
      const isValidState = Validators.state( value )
      setFormError( `state`, isValidState ? `` : regexErrors.state )
    }
    // Patient is attempting to change their address manually
    // This flag below lets internal know that the patient address was not verified by the autocomplete api
    if ( name !== `firstName` && name !== `lastName` ) setAddressPatientConfirmed( false )
    setAddress({
      ...address,
      [name]: value ?? ``
    })
  }

  const handleOnSave = () => {
    const hasError = evaluateErrorsOnSubmit()
    if ( hasError ) return
    if ( address.street === `` ) return setSubmitError( `Please enter a valid street address` )
    if ( shouldSaveOnSubmit ) updatePatientAddress()
    setAddressWebConfirmed( false ) // Resets this flag - This lets internal users know that the address was not verified by the verification api
    if ( shouldSkipValidation ) {
      if ( onSuccessfulConfirm ) onSuccessfulConfirm()

      return setCurrentView( `complete` )
    }
    setIsLoading( true )

    submitVerifyAddress({
      ...serializeToOriginalAddress( address ),
      environment: process.env.REACT_APP_ENVIRONMENT,
      source: `aeroflowsleep`
    }).then( ( response ) => {
      if ( response?.meta?.status === `OK` && response.data ) {
        const { data } = response
        setSubmitError( `` )
        // NOTE: If you make a change here, please double check and make sure you don't need it in SupremisePrompt.tsx
        setVerifiedAddress( serializeFromVerifiedAddress( data ) )
        setVerificationFlags( serializeVerificationFlags( data ) ) // Save these variables to be used in the confirm view
        setSuggestionHighlights( data.suggestion_highlights ) // Save for the verify view
        // unconfirmed_component_types is a list of components that are unable to be validated, we send the user to the error view if this list is not empty
        if ( data.invalid_address ) return setCurrentView( `invalid` )
        if ( data.prompt_for_subpremise ) return setCurrentView( `subpremise` ) // This is a special case where we need to prompt for subpremise
        if ( data.suggest_validated_address ) return setCurrentView( `verify` )
        setAddressWebConfirmed( true ) // This lets internal users know that the address was verified by the verification api
        return setCurrentView( `complete` )
      }
      setSubmitError( `Oops... we encountered an error attempting to update your address. Please try again or reach out to customer service at (800) 480-5491 if this issue persists.` )
      setCurrentView( `invalid` )
    })
      .finally( () => {
        setIsLoading( false )
        if ( onSuccessfulConfirm ) onSuccessfulConfirm()
      })
  }

  const labelClassName = `ml-4 font-light`

  return (
    <div className="flex flex-col mb-4 text-left gap-2">
      <TextInput
        label="First Name"
        labelClassName={labelClassName}
        name="firstName"
        value={address.firstName}
        onChange={handleChange}
        errorMessage={( !shouldHideErrors && formErrors?.firstName ) ? formErrors.firstName : ``}
        reference={refs.firstName}
        required
        onBlur={evaluateErrorsOnBlur}
      />
      <TextInput
        label="Last Name"
        labelClassName={labelClassName}
        name="lastName"
        value={address.lastName}
        onChange={handleChange}
        errorMessage={( !shouldHideErrors && formErrors?.lastName ) ? formErrors.lastName : ``}
        reference={refs.lastName}
        required
        onBlur={evaluateErrorsOnBlur}
      />
      <TypeAheadTextInput
        label="Street Address"
        labelClassName={labelClassName}
        name="street"
        onChange={handleChange}
        errorMessage={( !shouldHideErrors && formErrors.street ) ? formErrors.street : ``}
        value={address.street}
        required
        onBlur={evaluateErrorsOnBlur}
        ref={refs.street}
      />
      <TextInput
        label="Apt, suite, unit, building, floor, etc."
        labelClassName={labelClassName}
        name="street2"
        value={address.street2}
        onChange={handleChange}
        errorMessage={( !shouldHideErrors && formErrors.street2 ) ? formErrors.street2 : ``}
        reference={refs.street2}
        onBlur={evaluateErrorsOnBlur}
      />
      <div className="mb-8">
        <TextInput
          label="City"
          labelClassName={labelClassName}
          name="city"
          value={address.city}
          onChange={handleChange}
          errorMessage={( !shouldHideErrors && formErrors.city ) ? formErrors.city : ``}
          reference={refs.city}
          required
          onBlur={evaluateErrorsOnBlur}
        />

        <StateDropdownInput
          ref={refs.state}
          value={address.state}
          labelClassName={labelClassName}
          errorMessage={( !shouldHideErrors && formErrors.state ) ? formErrors.state : ``}
          onChange={handleChange}
          required
        />

        <TextInput
          label="Zip Code"
          labelClassName={labelClassName}
          name="zipCode"
          value={address.zipCode}
          onChange={handleChange}
          formatter={{
            function: Formatters.zipCode
          }}
          errorMessage={( !shouldHideErrors && formErrors.zipCode ) ? formErrors.zipCode : ``}
          reference={refs.zipCode}
          required
          onBlur={evaluateErrorsOnBlur}
        />
      </div>
      {submitError && <p className="text-error text-center mb-2">{submitError}</p>}
      {( submitLoading || isUpdatingPatientAddress ) && <p className="text-center">{`Verifying your address...`}</p>}
      {errorsPresent && <p className="text-center text-error mb-2">{`Please ensure all fields are complete and correct`}</p>}
      <button className="btn btn-secondary mx-auto" onClick={handleOnSave} disabled={errorsPresent || isLoading}>
        {`Confirm`}
      </button>
      {isLoading && <LoadingSpinner width="4rem" height="4rem" />}
    </div>
  )
}
