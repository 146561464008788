import { create } from 'zustand'
import { AccountTrackerDataType } from '../../components/accountTracker/types/AccountTrackerTypes'
import Order from '../../types/order'
import { Payer } from '../resupply2'
import { ApolloError } from '@apollo/client'
import { Customer } from '../aobPayments/components/paymentCustomerDetailsBlock/types/CustomerDataType'
import { createJSONStorage, persist } from 'zustand/middleware'
import { ProductResponse } from '../../components/account/YourProducts/ProductType'
import { ActiveInvoiceResponse, BillPayInvoiceResponse } from './tiles/Invoice'
import { Region } from '../../components/account/Insurance'

type StoreData<T> = {
  data: T | undefined;
  error: ApolloError | undefined
  loading: boolean
}

type AccountStore = {
  customer: StoreData<Customer>;
  accountTracker: StoreData<AccountTrackerDataType>;
  orders: StoreData<Order[]>;
  equipment: StoreData<ProductResponse[]>;
  regions: StoreData<Region[]>;
  payers: StoreData<Payer>;
  activeInvoice: StoreData<ActiveInvoiceResponse>;
  billPayInvoice: StoreData<BillPayInvoiceResponse>;
  setCustomer: ( _customer: StoreData<Customer> ) => void;
  setAccountTracker: ( _accountTracker: StoreData<AccountTrackerDataType> ) => void;
  setOrders: ( _orders: StoreData<Order[]> ) => void;
  setEquipment: ( _equipment: StoreData<ProductResponse[]> ) => void;
  setRegions: ( _insurance: StoreData<Region[]> ) => void;
  setPayers: ( _insurance: StoreData<Payer> ) => void;
  setActiveInvoice: ( _invoice: StoreData<ActiveInvoiceResponse> ) => void;
  setBillPayInvoice: ( _invoice: StoreData<BillPayInvoiceResponse> ) => void;
  evaluateIsLoading: ( ) => boolean;
}

export const useAccountStore = create<AccountStore>()(
  persist(
    ( set, get ) => {
      return {
        customer: {
          data: undefined,
          error: undefined,
          loading: true
        },
        accountTracker: {
          data: undefined,
          error: undefined,
          loading: true
        },
        orders: {
          data: [],
          error: undefined,
          loading: true
        },
        equipment: {
          data: [],
          error: undefined,
          loading: true
        },
        regions: {
          data: undefined,
          error: undefined,
          loading: true
        },
        payers: {
          data: undefined,
          error: undefined,
          loading: true
        },
        activeInvoice: {
          data: undefined,
          error: undefined,
          loading: true
        },
        billPayInvoice: {
          data: undefined,
          error: undefined,
          loading: true
        },
        setCustomer: ( _customer: StoreData<Customer> ) => ( set({
          customer: {
            ..._customer
          }
        }) ),
        setAccountTracker: ( _accountTracker: StoreData<AccountTrackerDataType> ) => ( set({
          accountTracker: {
            ..._accountTracker
          }
        }) ),
        setOrders: ( _orders: StoreData<Order[]> ) => ( set({
          orders: {
            ..._orders
          }
        }) ),
        setEquipment: ( _equipment: StoreData<ProductResponse[]> ) => ( set({
          equipment: {
            ..._equipment
          }
        }) ),
        setRegions: ( _regions: StoreData<Region[]> ) => ( set({
          regions: {
            ..._regions
          }
        }) ),
        setPayers: ( _payers: StoreData<Payer> ) => ( set({
          payers: {
            ..._payers
          }
        }) ),
        setActiveInvoice: ( _invoice: StoreData<ActiveInvoiceResponse> ) => ( set({
          activeInvoice: {
            ..._invoice
          }
        }) ),
        setBillPayInvoice: ( _invoice: StoreData<BillPayInvoiceResponse> ) => ( set({
          billPayInvoice: {
            ..._invoice
          }
        }) ),
        evaluateIsLoading: ( ) => {
          return get().customer.loading || get().accountTracker.loading || get().orders.loading || get().equipment.loading || get().regions.loading || get().payers.loading || get().activeInvoice.loading || get().billPayInvoice.loading
        }
      }
    },
    {
      name: `account-store`,
      storage: createJSONStorage( () => sessionStorage )
    })
)


