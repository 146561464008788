import React from 'react'
import PropTypes from 'prop-types'
import { icoInfoAob } from 'resources/images'
import Tooltip from 'components/tooltip'
import { PricingEstimatePercentageBar as PercentageBar } from 'components/percentageBar'
import { EstimateTooltipTemplate } from '../tooltips'
import { useGetAobSegmentedCopy } from 'modules/aobPayments/hooks/useGetAobSegmentedCopy'

function OutOfPocket({oop, oopRemaining}) {

  if ( oop === null || oopRemaining === null ) return null

  const pricingEstimateTooltips = useGetAobSegmentedCopy()

  return (
    <div className="relative flex flex-col items-center justify-center w-full mb-5">
      <div className="flex justify-center" >
        <p className="sm:text-sm lg:text-base text-center font-normal">{`Your Out of Pocket Limit ($${oop})`}</p>
        {pricingEstimateTooltips && (
          <Tooltip
            id="oop"
            tooltipContent={<EstimateTooltipTemplate {...pricingEstimateTooltips.oop} />}
          >
            <img
              src={icoInfoAob}
              alt="ico-info"
              className="px-2 hover:opacity-80 cursor-pointer object-contain"
              name="oop"
              width={37}
            />
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col w-full lg:w-10/12 px-2 my-2">
        <PercentageBar
          percentFilled={oop > 0 ? Math.round( ( ( oop - oopRemaining ) / oop ) * 100 ) : 100}
          values={{
            paid: oop - oopRemaining,
            toGo: oopRemaining
          }}
        />
      </div>
      <>
        {
          oopRemaining === 0 &&
            <p className="text-base text-center font-light text-graphite">{`Your out of pocket limit has been met`}</p>
        }
      </>
    </div>
  )
}

OutOfPocket.propTypes = {
  oop: PropTypes.number,
  oopRemaining: PropTypes.number
}

export default OutOfPocket