import create from 'zustand'

type DoctorState = {
  isDoctorComplete: boolean
  setIsDoctorComplete: ( _value: boolean ) => void
}

export const useDoctorStore = create<DoctorState>( ( set ) => ({
  isDoctorComplete: false,
  setIsDoctorComplete: ( value ) => set({
    isDoctorComplete: value
  })
}) )