import DoctorInfo from '../types/DoctorInfo'
import DoctorInput from '../types/DoctorInput'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type DoctorState = {
  showDoctorDisplayModal: boolean;
  setShowDoctorDisplayModal: ( _show: boolean ) => void;
  doctorInput: DoctorInput;
  setDoctorInput: ( _doctorInput: DoctorInput ) => void;
  doctor: DoctorInfo | null;
  setDoctor: ( _doctorInfo: DoctorInfo ) => void;
}

export const useDoctorStore = create<DoctorState>()(
  persist(
    ( set ) => ({
      showDoctorDisplayModal: false,
      setShowDoctorDisplayModal: ( _show: boolean ) => set({
        showDoctorDisplayModal: _show
      }),
      doctorInput: {
        zipCode: ``,
        range: 10,
        name: ``
      },
      setDoctorInput: ( _doctorInput: DoctorInput ) => set({
        doctorInput: _doctorInput
      }),
      doctor: null,
      setDoctor: ( _doctorInfo: DoctorInfo ) => set({
        doctor: _doctorInfo
      })
    }),
    {
      name: `doctor-store`,
      storage: createJSONStorage( () => sessionStorage )
    }
  )
)