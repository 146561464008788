import React from 'react'
import { ADDRESS, FAX, HOURS, PHONE } from '../constants'
import { getThisYear } from 'utils/time'

const ContactBlock = () => {
  const thisYear = getThisYear()

  return (
    <div>
      <ul className="p-0 m-0 sm:text-center lg-wide:text-left">
        <li className="text-sm lg:text-base mb-1">{ADDRESS}</li>
        <li className="text-sm lg:text-base mb-1">{`Phone `}
          <a href={`tel:+${PHONE}`} className="text-hyperLinkBlue hover:underline">{PHONE}</a>
        </li>
        <li className="text-sm lg:text-base mb-1">{`Fax `}{FAX}</li>
        <li className="text-sm lg:text-base mb-1">{HOURS}</li>
        <li className="text-sm lg:text-base">{`Copyright © ${thisYear}`}</li>
      </ul>
    </div>
  )
}

export default ContactBlock
