import create from 'zustand'
import { persist } from 'zustand/middleware'
import { SNL_MASK_TYPE_PATH, SNL_MASK_BRAND_PATH, SNL_MASK_MODEL_PATH, SNL_MACHINE_BRAND_PATH, SNL_MACHINE_MODEL_PATH } from '../constants'

type SNLFormDataType = {
  stepComplete: number;
  lastOrderCpapSupplies: string;
  lastOrderCpapMachine: number;
  userKnowsMaskDetailsFlag: boolean;
  userKnowsMachineDetailsFlag: boolean;
  cpapMaskDmeId: number;
  cpapMaskBrand: string;
  cpapMaskModel: string;
  cpapMaskSize: string;
  cpapMachineDmeId: number;
  cpapMachineBrand: string;
  cpapMachineModel: string;
  cpapMachineHumidifierFlag: boolean;
  tubingDmeId: number;
  tubingType: string;
  lastOrderHadHeadgearFlag: number;
  lastOrderHadWaterChamberFlag: number;
  userRequestsContactFlag: boolean;
  intentOrderNewEquipment: boolean;
  lastOrderCpapMask: string;
  lastOrderCpapMaskHeadgear: string;
  satisfiedWithCurrentMask: boolean;
  webConfirmed: boolean;
  patientConfirmed: boolean;
  cpapMaskType?: string;
}

interface SNLState {
  formValues: SNLFormDataType;
  activeStep: number;
  highestStep: number;
  doesNotWantMask: boolean;
  email: string;
}

export const useSNLStore = create( persist( ( set, get ) => ({
  formValues: {
    cpapMaskType: ``,
    cpapMaskDmeID: ``,
    cpapMaskBrand: ``,
    cpapMaskModel: ``,
    cpapMaskSize: ``,
    lastOrderCpapMask: ``,
    lastOrderCpapMaskHeadgear: ``,
    satisfiedWithCurrentMask: ``,
    cpapMachineDmeID: ``,
    cpapMachineBrand: ``,
    cpapMachineModel: ``,
    lastOrderCpapMachine: ``,
    intentOrderNewEquipment: ``,
    tubingDmeID: ``,
    tubingType: ``,
    lastOrderCpapSupplies: ``,
    addressAcceptedFlag: ``,
    doctorAcceptedFlag: ``
  },
  activeStep: 1, // Default to 1
  highestStep: 1, // Default to 1
  snlEmail: ``,
  doesNotWantMask: false,
  setDoesNotWantMask: ( value: boolean ) => set({
    doesNotWantMask: value
  }),
  setSnlEmail: ( value: string ) => set({
    snlEmail: value
  }),
  snlEditSelection: () => {
    const { formValues } = get() as SNLState
    return [
      formValues?.cpapMaskType && {
        label: `Select your current mask type`,
        value: formValues?.cpapMaskType,
        step: `cpapMaskType`,
        path: SNL_MASK_TYPE_PATH
      },
      formValues?.cpapMaskBrand && {
        label: `Select your current mask brand`,
        value: formValues?.cpapMaskBrand,
        step: `cpapMaskBrand`,
        path: SNL_MASK_BRAND_PATH
      },
      formValues?.cpapMaskModel && {
        label: `Select your current mask model`,
        value: formValues?.cpapMaskModel,
        step: `cpapMaskModel`,
        path: SNL_MASK_MODEL_PATH
      },
      formValues?.cpapMachineBrand && {
        label: `Select your current machine brand`,
        value: formValues?.cpapMachineBrand,
        step: `cpapMachineBrand`,
        path: SNL_MACHINE_BRAND_PATH
      },
      formValues?.cpapMachineModel && {
        label: `Select your current machine model`,
        value: formValues?.cpapMachineModel,
        step: `cpapMachineModel`,
        path: SNL_MACHINE_MODEL_PATH
      }
    ].filter( Boolean )
  },
  snlUpdateSession: ( formValues: SNLFormDataType, activeStep: number, highestStep: number ) => set({
    formValues: formValues !== undefined ? formValues : null,
    activeStep: activeStep !== undefined ? activeStep : null,
    highestStep: highestStep !== undefined ? highestStep : null
  }),
  clearSession: () => set({
    formValues: {},
    activeStep: 0,
    highestStep: 0,
    doesNotWantMask: false,
    snlEditSelection: () => [],
    isSleepNewLeads: false,
    snlEmail: ``
  })
}), {
  name: `snlSessionStorage`,
  getStorage: () => sessionStorage
}) )

export function getSessionData() {
  const { formValues, activeStep, highestStep } = useSNLStore.getState() as SNLState
  return {
    formValues,
    activeStep,
    highestStep
  }
}