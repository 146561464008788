import { ApolloError, useQuery, useReactiveVar } from '@apollo/client'
import { customerAddressesQuery, getProductsQueryBySkuArray, regionsQuery } from 'graphql/queries'
import { reportToSentry } from '../../utils/reportToSentry'
import { useAccountStore } from './state'
import { accountTrackerDataFetch } from '../../components/accountTracker/utils/utils'
import {
  fetchAwsResupplyOrderHistory
} from '../../components/account/ResupplyOrderHistory/fetchAwsResupplyOrderHistory'
import { useEffect } from 'react'
import { accountNavOptions, patientIdFromAppServer } from '../../apollo'
import { apolloClient } from 'apollo/apolloClient'
import { fetchResupplyOptions } from '../resupply2/hooks/fetchPromises'
import { getResupplyProductsViaOptionsFetchErrorDisplayMessage } from './utils'
import usePatientRouting from '../../hooks/PatientRouting/usePatientRouting'
import { fetchBalance, fetchPatientInvoice } from '../aobPayments/fetchPromises'
import { myInvoiceId } from './constants'
import { BalanceAvailableType } from './tiles/Invoice'
import { usaStates } from '../../components/forms/constants'
import { getProductPricing } from '../../utils/patientInfoApi'

export function fetchAccountData() {
  const controller = new AbortController()
  const backupPatientId = useReactiveVar( patientIdFromAppServer )
  const { setCustomer, setAccountTracker, setOrders, setEquipment, setPayers, setRegions, setActiveInvoice, setBillPayInvoice } = useAccountStore()

  // fetch customer data
  useQuery( customerAddressesQuery, {
    onCompleted: ( data ) => {
      setCustomer({
        data: data.customer,
        error: undefined,
        loading: false
      })

      const patient_id = data?.customer?.patient_id ?? backupPatientId
      if ( !patient_id ) {
        // we can't fetch resupply order history and products without patient id
        setOrders({
          data: undefined,
          error: new ApolloError({
            errorMessage: `Error fetching patient id`
          }),
          loading: false
        })
        setEquipment({
          data: undefined,
          error: new ApolloError({
            errorMessage: `Error fetching patient id`
          }),
          loading: false
        })
        reportToSentry( `useAccountData: there is no patient id to get account data`, {
          reason: JSON.stringify({
            data: data
          })
        })
      } else if ( patient_id ) {
        // once patient id is acquired
        // fetch resupply order history
        fetchAwsResupplyOrderHistory( )
          .then( ( response ) => {
            if ( response?.data ) {
              setOrders({
                data: Object.values( response.data ),
                error: undefined,
                loading: false
              })
            }
          })
          .catch( ( err: Error ) => {
            reportToSentry( `useAccountData: Error fetching resupply order history`, {
              reason: JSON.stringify({
                error: err,
                data: data
              })
            })
            setOrders({
              data: undefined,
              error: new ApolloError({
                errorMessage: err.message
              }),
              loading: false
            })
          })
        // fetch products
        fetchResupplyOptions( patient_id, `myaccount`, controller.signal )
          .then( async ({ data }) => {
            if ( !data?.items?.length ) return []

            const skusArray = data?.items.map( ( item: {hcpc_id: string}) => { return item.hcpc_id })
            const magentoResponse = await apolloClient.query({
              query: getProductsQueryBySkuArray,
              fetchPolicy: `network-only`,
              variables: {
                skus: skusArray
              }
            })

            if ( !magentoResponse?.data || !magentoResponse?.data?.resupplyProducts?.items?.length ) return []

            return data?.items.map( ( item: {magento_sku: string}) => {
              const magentoProduct = magentoResponse.data.resupplyProducts.items.find( ( magentoItem: {sku: string}) => {
                return magentoItem.sku === item.magento_sku
              })

              return {
                ...item,
                image: magentoProduct?.image ?? magentoProduct?.small_image ?? undefined
              }
            })
          })
          .then( customerProducts => {
            if ( !controller.signal.aborted && customerProducts ) {
              setEquipment({
                data: customerProducts,
                error: undefined,
                loading: false
              })
            } else {
              setEquipment({
                data: undefined,
                error: new ApolloError({
                  errorMessage: `No products returned for patient id ${patient_id}`
                }),
                loading: false
              })
            }
          })
          .catch( ( error ) => {
            if ( !controller.signal.aborted ) {
              setEquipment({
                data: undefined,
                error: new ApolloError({
                  errorMessage: getResupplyProductsViaOptionsFetchErrorDisplayMessage( error )
                }),
                loading: false
              })
              reportToSentry( new Error( `useAccountData: Error getting resupply options`, {
                cause: error
              }), {
                patientId: patient_id
              })
            }
          })
      }
    },
    onError: ( error ) => {
      setCustomer({
        data: undefined,
        error,
        loading: false
      })
      setOrders({
        data: undefined,
        error: new ApolloError({
          errorMessage: `Error fetching patient id`
        }),
        loading: false
      })
      setEquipment({
        data: undefined,
        error: new ApolloError({
          errorMessage: `Error fetching patient id`
        }),
        loading: false
      })
      reportToSentry( `useAccountData: Error from customerAddressesQuery`, {
        error: error?.message,
        jsonError: JSON.stringify( error )
      })
    }
  })

  // fetch account tracker data
  useEffect( () => {
    accountTrackerDataFetch()
      .then( ( response ) => {
        if ( response?.data && response?.meta?.status === `OK` ) {
          setAccountTracker({
            data: response.data,
            error: undefined,
            loading: false
          })
        } else {
          setAccountTracker({
            data: undefined,
            error: response,
            loading: false
          })
        }
      })
      .catch( ( error ) => {
        setAccountTracker({
          data: undefined,
          error,
          loading: false
        })
        reportToSentry( new Error( `useAccountData: Error retrieving account tracker data`, {
          cause: error
        }) )
      })
  }, [] )

  // fetch regions
  useQuery( regionsQuery, {
    onCompleted: ( data ) => {
      if ( data?.countries?.length ) {
        // filter and set regions data
        let availableRegions = data.countries.find( ( country: {id: string}) => {
          return country.id === `US`
        })?.available_regions

        const stateCodes = usaStates.map( usaState => { return usaState.value })

        availableRegions = availableRegions.filter( ( region: {code: string}) => {
          return stateCodes.includes( region.code )
        })
        setRegions({
          data: availableRegions,
          error: undefined,
          loading: false
        })
      }
    },
    onError: ( error ) => {
      setRegions({
        data: undefined,
        error: error,
        loading: false
      })
    }
  })

  // fetch payers
  useEffect( () => {
    getProductPricing( true )
      .then( ( data ) => {
        setPayers({
          data: data.data.patient.payers,
          error: undefined,
          loading: false
        })
      })
      .catch( ( error ) => {
        setPayers({
          data: undefined,
          error: error,
          loading: false
        })
        reportToSentry( new Error( `Error getting payers`, {
          cause: JSON.stringify( error ?? `` )
        }) )
      })
  }, [ ] )


  // fetch active invoice
  const { data } = usePatientRouting()
  const hash = data?.patient_hash
  const currentOptions = useReactiveVar( accountNavOptions )
  const checkHasBalance = ({data} : BalanceAvailableType ) => {
    //  check if dominant division is sleep and current balance flag is true
    return data.find( ( item ) => item.division === `Sleep` && item.current_balance_flag )
  }

  useEffect( () => {
    if ( hash ) {
      // check if hash pulls down a valid active patient invoice
      fetchPatientInvoice( hash ).then( data => {
        if ( data?.meta?.status === `OK` && data?.data ) {
          setActiveInvoice({
            data: {
              activeInvoice: true,
              hash
            },
            error: undefined,
            loading: false
          })
          // add invoice option to account nav options if active invoice
          const myInvoiceOption = currentOptions.find( ( option ) => { return option.id === myInvoiceId })
          if ( !myInvoiceOption ) accountNavOptions( [{
            label: `Invoice`,
            id: myInvoiceId
          }, ...currentOptions ] )
        } else {
          setActiveInvoice({
            data: {
              activeInvoice: false,
              hash
            },
            error: undefined,
            loading: false
          })
        }
      })
        .catch( ( error ) => {
          reportToSentry( new Error( `useAccountData: Error getting active invoice data`, {
            cause: error
          }),
          {
            hash
          })
          setActiveInvoice({
            data: undefined,
            error: new ApolloError({
              errorMessage: error?.errorMessage
            }),
            loading: false
          })
        })
    } else {
      setActiveInvoice({
        data: undefined,
        error: new ApolloError({
          errorMessage: `No data available`
        }),
        loading: false
      })
    }
  }, [ data ] )

  // fetch bill pay invoice
  useEffect( () => {
    // check if patient has a balance then build link for the bill pay site
    fetchBalance( ).then( data => {
      if ( data?.meta?.status === `OK` && data?.data?.length ) {
        const balanceData = data?.data[0]
        if ( checkHasBalance( data ) ) {
          // add invoice option to account nav options if active invoice
          const myInvoiceOption = currentOptions.find( ( option ) => { return option.id === myInvoiceId })
          if ( !myInvoiceOption ) accountNavOptions( [{
            label: `Invoice`,
            id: myInvoiceId
          }, ...currentOptions ] )
          const url = balanceData?.patient_hash ? `${process.env.REACT_APP_BILL_PAY_URL}/?sgh=${balanceData.patient_hash}` : ``
          const balance = balanceData?.current_balance ? balanceData.current_balance.toFixed( 2 ) : ``
          setBillPayInvoice({
            data: {
              billPayUrl: url,
              billPayAmount: balance
            },
            error: undefined,
            loading: false
          })
        } else {
          setBillPayInvoice({
            data: undefined,
            error: undefined,
            loading: false
          })
        }
      } else {
        setBillPayInvoice({
          data: undefined,
          error: new ApolloError({
            errorMessage: `No data available`
          }),
          loading: false
        })
      }
    })
      .catch( ( error ) => {
        reportToSentry( new Error( `useAccountData: Error fetching bill pay for patient`, {
          cause: error
        }),
        {
          hash
        })
        setBillPayInvoice({
          data: undefined,
          error: new ApolloError({
            errorMessage: error.message
          }),
          loading: false
        })
      })
  }, [] )
}

export function refetchCustomer() {
  const {setCustomer} = useAccountStore()
  useQuery( customerAddressesQuery, {
    onCompleted: ( data ) => {
      setCustomer({
        data: data.customer,
        error: undefined,
        loading: false
      })
    },
    onError: ( error ) => {
      setCustomer({
        data: undefined,
        error,
        loading: false
      })
      reportToSentry( new Error( `refetchCustomer: Error retrieving customer addresses query`, {
        cause: error
      }) )
    }
  })
}