import React from 'react'
import { icoCaretLeftNavy } from 'resources/images'

type BackButtonProps = {
  handleClick(): void;
  tailwindPositioning: string;
  buttonLabel?: string;
}

function BackButton({handleClick, tailwindPositioning, buttonLabel}: BackButtonProps ) : JSX.Element {
  return (
    <div className={`rounded-full ${tailwindPositioning}`}>
      <button
        className="rounded-md hover:opacity-60 border border-[#004C97] px-3 py-1 bg-white flex items-center"
        onClick={handleClick}
      >
        <img
          src={icoCaretLeftNavy}
          alt="back_button"
          className="w-1"
        />
        {buttonLabel && <span className="ml-1 text-xs leading-none font-light text-[#004C97]">{buttonLabel}</span>}
      </button>
    </div>
  )
}

export default BackButton