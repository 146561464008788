import React from 'react'
import PropTypes from 'prop-types'
import { icoInfoAob } from 'resources/images'
import Tooltip from 'components/tooltip'
import { PricingEstimatePercentageBar as PercentageBar } from 'components/percentageBar'
import { EstimateTooltipTemplate } from '../tooltips'
import { useGetAobSegmentedCopy } from 'modules/aobPayments/hooks/useGetAobSegmentedCopy'

function Deductible({deductible, deductibleRemaining}) {

  if ( deductible === null || deductibleRemaining === null ) return null

  const pricingEstimateTooltips = useGetAobSegmentedCopy()

  return (
    <div className="relative flex flex-col items-center justify-center w-full mb-5">
      <div className="flex justify-center" >
        <p className="sm:text-sm lg:text-base text-center font-normal">{`Your Deductible ($${deductible})`}</p>
        {pricingEstimateTooltips && (
          <Tooltip
            id="deductible"
            tooltipContent={<EstimateTooltipTemplate {...pricingEstimateTooltips.deductible} />}
          >
            <img
              src={icoInfoAob}
              alt="ico-info"
              className="px-2 hover:opacity-80 cursor-pointer object-contain"
              name="deductible"
              width={37}
            />
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col w-full lg:w-10/12 px-2 my-2">
        <PercentageBar
          percentFilled={deductible > 0 ? Math.round( ( ( deductible - deductibleRemaining ) / deductible ) * 100 ) : 100}
          values={{
            paid: deductible - deductibleRemaining,
            toGo: deductibleRemaining
          }}
        />
      </div>
      <>
        {
          deductibleRemaining === 0 &&
          <p className="text-base text-center font-light text-graphite">{`Your deductible has been met`}</p>
        }
      </>
    </div>
  )
}

Deductible.propTypes = {
  deductible: PropTypes.number,
  deductibleRemaining: PropTypes.number
}

export default Deductible