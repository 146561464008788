import React from 'react'
import PropTypes from 'prop-types'

const PageTracker = ({title, description, children }) => {
  return (
    <div className="flex flex-col justify-center sm:items-center lg:items-start sm:mb-4 lg:mb-[42px] max-w-[700px] sm:mx-auto lg:mx-0">
      <h2 className="sm:text-3xl md:text-[42px] font-semibold font-inter text-[#0057A8] sm:text-center lg:text-left">{title}</h2>
      { description &&
        <p className="text-graphite font-inter text-center sm:text-sm md:text-xl mt-2 sm:text-center lg:text-left">{description}</p>
      }
      { children }
    </div>
  )
}

PageTracker.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] ),
  children: PropTypes.node
}

export default PageTracker
