import React from 'react'
import PropTypes from 'prop-types'
import { icoInfoAob } from 'resources/images'
import Tooltip from 'components/tooltip'
import { PricingEstimatePercentageBar as PercentageBar } from 'components/percentageBar'
import { EstimateTooltipTemplate } from '../tooltips'
import { useGetAobSegmentedCopy } from 'modules/aobPayments/hooks/useGetAobSegmentedCopy'

function CoInsurance({coIns}) {

  if ( coIns === null ) return null

  const coInsRemaining = Math.round( 100 - coIns )

  const pricingEstimateTooltips = useGetAobSegmentedCopy()

  return (
    <div className="relative flex flex-col items-center justify-center w-full mb-5">
      <div className="flex justify-center" >

        <p className="sm:text-sm lg:text-base text-center font-normal">{`Co-Insurance`}</p>
        {pricingEstimateTooltips && (
          <Tooltip
            id="coinsurance"
            tooltipContent={<EstimateTooltipTemplate {...pricingEstimateTooltips.coIns} />}
          >
            <img
              src={icoInfoAob}
              alt="ico-info"
              className="px-2 hover:opacity-80 cursor-pointer object-contain"
              name="coIns"
              width={37}
            />
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col w-full lg:w-10/12 px-2 my-2">
        <PercentageBar percentFilled={coInsRemaining} isCoInsurance />
      </div>
      <p className="sm:text-sm lg:text-base text-center font-normal text-[#535353]">{`Your insurance covers ${coInsRemaining}%, you cover ${Math.round( coIns )}%`}</p>
    </div>
  )
}

CoInsurance.propTypes = {
  coIns: PropTypes.number
}

export default CoInsurance