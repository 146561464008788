import { RadioInput } from "components/forms/components"
import AddressP from "../components/AddressP"
import Highlight from "../components/Highlight"
import { useAddressStore } from "../state"
import Alert from "../components/Alert"
import HighlightP from "../components/HighlightP"

export default function VerifyAddress() : JSX.Element {
  const { address, setAddress, verifiedAddress, verificationFlags, setCurrentView, setAddressWebConfirmed } = useAddressStore()
  const { promptForSubpremise } = verificationFlags
  const [ selected, setSelected ] = useAddressStore( state => [ state.userConfirmedAddress, state.setUserConfirmedAddress ] )

  const selectOriginal = () => setSelected( `original` )
  const selectVerified = () => setSelected( `verified` )

  const handleContinue = () => {
    if ( selected === `original` ) setCurrentView( `confirm` )
    else {
      setAddress({
        ...address, // This brings over firstName, lastName
        ...verifiedAddress,
        zipCode: verifiedAddress.zipCode.split( `-` )[ 0 ] // remove +4
      })
      if ( promptForSubpremise ) return setCurrentView( `subpremise` )
      setAddressWebConfirmed( true ) // This lets internal users know that the address was verified by the verification api
      return setCurrentView( `complete` )
    }
  }

  return (
    <div className="max-w-md mx-auto">
      <div className="flex flex-col items-start gap-6">
        <div className="self-center"><Alert /></div>
        <p className="uppercase ml-8 -mb-4"><Highlight>{`You entered:`}</Highlight></p>
        <div className="flex flex-row gap-2 cursor-pointer" onClick={selectOriginal}>
          <RadioInput
            className="cursor-pointer themed-radio"
            value="yes"
            name={`verifyAddress`}
            checked={selected === `original`}
            onChange={selectOriginal}
          />
          <AddressP address={address} />
        </div>

        <p className="uppercase ml-8 -mb-4"><Highlight>{`Suggested Address:`}</Highlight></p>
        <div className="flex flex-row gap-2 cursor-pointer" onClick={selectVerified}>
          <RadioInput
            className="cursor-pointer themed-radio"
            value="yes"
            name={`verifyAddress`}
            checked={selected === `verified`}
            onChange={selectVerified}
          />
          <HighlightP />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="underline text-royalBlue cursor-pointer text-center" onClick={() => setCurrentView( `edit` )}>{`Edit Address`}</p>
        </div>

        <div className="w-full flex flex-col gap-4">
          <button className="btn btn-secondary" onClick={handleContinue}>{`Confirm Selection`}</button>
        </div>
      </div>
    </div>
  )
}