import React, { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { accountNavOptions } from 'apollo'

import { Tile } from 'components/tile'
import { myEquipmentId } from 'modules/accountV2/constants'
import { useAccountStore } from '../state'
import Products from '../../../components/account/YourProducts/Products'
import Error from '../components/Error'

function Equipment(): JSX.Element | null {
  const { customer, equipment } = useAccountStore()
  const currentNavOptions = useReactiveVar( accountNavOptions )
  const myEquipmentNavOption = currentNavOptions.find( ( option ) => { return option.id === myEquipmentId })

  // add My Equipment to navigate dropdown
  useEffect( () => {
    if ( equipment?.data?.length && !myEquipmentNavOption ) accountNavOptions( [
      ...currentNavOptions,
      {
        label: `My Equipment`,
        id: myEquipmentId
      }
    ] )
  }, [ equipment, myEquipmentNavOption ] )

  if ( customer?.error || equipment?.error ) {
    return (
      <Error title="My Equipment" id={myEquipmentId} fillInTheBlank="equipment" />
    )
  }

  return (
    <Tile headerContent="My Equipment" id={myEquipmentId}>
      {
        !equipment?.data?.length ?
          <p className="text-deepSleepBlue text-center my-8">{`You have no equipment associated with this account.`}</p>
          :
          <>
            <Products products={equipment.data} />
          </>
      }
    </Tile>
  )
}

export default Equipment