import React from 'react'
import { useDoctorStore } from '../hooks/state'
import DoctorResult from '../types/DoctorResult'

type DoctorActionBlockProps = {
  searchResults: DoctorResult[] | null;
  searchError: string;
  zipDisplay: boolean;
  setManualDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  handleBack: () => void;
  forceUpdate: boolean;
  isMyAccount?: boolean;
}

function DoctorSearchActionBlock({ searchResults, searchError, zipDisplay, setManualDisplay, handleBack, forceUpdate, isMyAccount = false } : DoctorActionBlockProps ) : JSX.Element {
  const { doctor } = useDoctorStore()

  const handleTriggerManualDoctor = () => {
    setManualDisplay( true )
    if ( !isMyAccount ) {
      const doctorBlockElement = document.getElementById( `doctor-block` )
      doctorBlockElement?.scrollIntoView({
        behavior: `smooth`
      })
    }
  }

  return (
    <>
      {
        ( searchResults || searchError ) && !zipDisplay && (
          <div className="flex flex-col items-center">
            <p className={`${searchResults && searchResults.length > 0 && `mt-5`} font-bold`}>{`Dont see your doctor?`}</p>
            <p
              className="mt-3 font-light underline text-deepSleepBlue decoration-deepSleepBlue cursor-pointer"
              onClick={handleTriggerManualDoctor}
            >
              {`Add your doctor manually`}
            </p>
          </div>
        )
      }
      {
        ( ( doctor && !zipDisplay && !forceUpdate ) || isMyAccount ) &&
            <div className={isMyAccount ? `mt-1` : `mt-10`}>
              <button
                className="btn-primary"
                onClick={handleBack}
              >
                {`Cancel`}
              </button>
            </div>
      }
    </>
  )
}

export default DoctorSearchActionBlock