import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { RecurringPaymentBlock, UpfrontPaymentBlock, AobLoader, ProcessPaymentButton } from '../components'
import { getNextMonthDate } from 'utils/time'
import { splitMoney } from 'utils/money'
import { reportToSentry } from 'utils/reportToSentry'
import { chargeV2, updateInvoice } from '../fetchPromises'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useNavigate } from 'react-router-dom'
import { SUMMARY_PAGE_PATH } from '../constants'
import { buildChargeBody } from '../utils'
import HeadingWithBackButton from '../components/backButton/Heading'
import { useGetAobSegmentedCopy } from 'modules/aobPayments/hooks/useGetAobSegmentedCopy'
import AddressBlock, { useAddressStore, overrideAddressBlockStyle } from 'components/addressBlock'
import AobContactBlock from '../components/contactBlock/aobContactBlock'

const Payments = ({ ...pageProps }) => {
  const {
    invoice,
    payLater,
    authnetError,
    aobIsCompleted,
    setPaymentCompleted,
    removedItems
  } = pageProps

  // ############# S T A T E  H O O K S #############
  const paymentDisclaimerCopy = useGetAobSegmentedCopy()

  const [ singlePaymentMethod, setSinglePaymentMethod ] = useState( null )
  const [ recurringPaymentMethod, setRecurringPaymentMethod ] = useState( null )
  const [ shouldUseSingleForRecurring, setShouldUseSingleForRecurring ] = useState( true )

  const [ splitSinglePayment, setSplitSinglePayment ] = useState( null )

  const [ paymentLoading, setPaymentLoading ] = useState( false )
  const [ paymentError, setPaymentError ] = useState( `` )

  const [ shouldSkipRecurring, setShouldSkipRecurring ] = useState( false )
  const [ disableSubmitPayment, setDisableSubmitPayment ] = useState( false )

  const navigate = useNavigate()
  const { isAddressComplete } = useAddressStore()

  // ############# S T Y L E  O V E R R I D E S #############
  overrideAddressBlockStyle({
    headerStyle: `text-xl tracking-wide font-medium text-center my-4`,
    containerStyle: `py-4`,
    wrapperStyle: `mt-9 mb-6 !bg-[#F7F7F7] rounded-lg px-8 py-4`,
    view: {
      textStyle: ``,
      editActionClassName: ``
    }
  })

  // ############# E F F E C T  H O O K S #############
  useEffect( () => {
    if ( payLater || invoice?.total_patient_owes === 0 ) {
      handleUpdateInvoice( invoice, removedItems )
      pageProps.nextPage()
    } else if ( !aobIsCompleted ) navigate( SUMMARY_PAGE_PATH.concat( window.location.search ) )
    else if ( invoice !== null && ( !invoice || !Object.keys( invoice ).length ) ) pageProps.nextPage()
  }, [] )

  const handleUpdateInvoice = async () => {
    if ( !removedItems.length ) return

    const urlParams = new URLSearchParams( window.location.search )
    const patientHash = urlParams.get( `sgh` ) ?? urlParams.get( `txt` )

    const updateInvoiceResponse = await updateInvoice( removedItems, patientHash )
      .catch( error => {
        reportToSentry( `AOB Updating Invoice Failure`, {
          error,
          patientHash,
          invoice: invoice.invoice_number,
          itemsToRemove: removedItems
        })
      })

    if ( updateInvoiceResponse?.meta?.status !== `OK` || !updateInvoiceResponse?.data?.success ) {
      reportToSentry( `AOB Updating Invoice Failure`, {
        patientHash,
        invoice: invoice.invoice_number,
        itemsToRemove: removedItems
      })
    } else sessionStorage.removeItem( `invoice-control` )
  }

  const scrollToError = () => {
    // @TODO we display the submit payment error toward the bottom of the screen
    // this function scrolls the user to the top
    // we need to make sure it shows them the error
    window.scrollTo({
      top: 9999,
      behavior: `smooth`
    })
  }

  // ############# PAYMENTS #############
  async function submitPayment() {

    if ( singlePaymentMethod === null ) return setPaymentError( `Please select a payment method and try again.` )
    // clear previous errors before attempting a new payment
    setPaymentError( false )
    setPaymentLoading( true )

    const recurringAuthnetResponse = await submitRecurringCPAPRentalPayment()

    if ( recurringAuthnetResponse !== `Successful.` ) {
      setPaymentLoading( false )
      reportToSentry( new Error( `AOB: Failed to make RECURRING payment ${recurringAuthnetResponse ?? ``}` ), {
        recurringAuthnetResponse,
        invoiceDetails: JSON.stringify( invoice ),
        paymentDetails: JSON.stringify( recurringPaymentMethod )
      })
      scrollToError()
      if ( recurringAuthnetResponse.includes( `Credit Card expires before the start of the subscription` ) ) return setPaymentError( `Looks like the card you have selected for your subscription will expire by the time the subscription is started. Please select/add another card or give us a call at ${AF_SUPPORT_PHONE_NUMBER} if you need help or have any questions.` )
      if ( recurringAuthnetResponse.includes( `duplicate subscription` ) ) return setPaymentError( `Looks like you've already made a payment. Please give us a call at ${AF_SUPPORT_PHONE_NUMBER} if you need help or have any questions.` )

      return setPaymentError( `We encountered an issue when attempting to pay your rental payment with the selected card. Please select/add another card or contact customer service at ${AF_SUPPORT_PHONE_NUMBER}.` )

    } else setShouldSkipRecurring( true ) // If a user has completed a recurring charge, but needs to retry the upfront charge we need to know when to skip

    /* Begin Upfront Payment */

    const upfrontAuthnetResponse = await submitUpfrontPayment().catch( ( err ) => {
      setPaymentLoading( false )
      reportToSentry( new Error( `AOB: Failed to make UPFRONT payment`, {
        cause: err
      }) )
      setPaymentError( `There was an error processing your payment. Please try again. If issues persist please contact customer service` )

      return scrollToError()
    })

    setPaymentLoading( false )

    if ( upfrontAuthnetResponse === `Successful.` && recurringAuthnetResponse === `Successful.` ) {
      handleUpdateInvoice( invoice, removedItems )
      setPaymentCompleted( true )

      return pageProps.nextPage()
    } else {

      scrollToError()
      if ( upfrontAuthnetResponse?.includes( `duplicate subscription` ) ) return setPaymentError( `Looks like you've already made a payment. Please give us a call at ${AF_SUPPORT_PHONE_NUMBER} if you need help or have any questions.` )
      if ( upfrontAuthnetResponse?.includes( `Credit Card expires before the start of the subscription` ) ) return setPaymentError( `Looks like the card you have selected for your subscription will expire by the time the subscription is started. Please select/add another card or give us a call at ${AF_SUPPORT_PHONE_NUMBER} if you need help or have any questions.` )
      setPaymentError( `We encountered an issue when attempting to pay your upfront payment with the selected card. Please select/add another card or contact customer service at ${AF_SUPPORT_PHONE_NUMBER}.` )

      reportToSentry( new Error( `AOB: Failed to make UPFRONT payment ${upfrontAuthnetResponse ?? ``}` ), {
        upfrontAuthnetResponse,
        recurringAuthnetResponse,
        invoiceDetails: JSON.stringify( invoice ),
        paymentDetails: JSON.stringify( singlePaymentMethod )
      })
    }
  }

  async function submitRecurringCPAPRentalPayment() {
    if ( !invoice.est_monthly_due || !invoice.number_of_months || shouldSkipRecurring ) return `Successful.`
    const recurringChargeDetails = {
      startDate: getNextMonthDate().toISOString()
        .slice( 0, 10 ),
      intervalLength: 1,
      totalOccurrences: invoice.number_of_months,
      name: `PAP Machine Monthly Rental Payment`
    }
    const paymentMethod = shouldUseSingleForRecurring ? singlePaymentMethod : recurringPaymentMethod

    const CPAPRentalChargeBody = buildChargeBody( invoice.est_monthly_due, paymentMethod, {
      ...recurringChargeDetails,
      initialPayment: false,
      ...( invoice?.invoice_number && {
        invoiceNumber: invoice.invoice_number
      })
    })

    return await chargeV2( CPAPRentalChargeBody )
  }

  async function submitUpfrontPayment() {
    const amount = splitSinglePayment ? splitMoney( invoice.total_patient_owes, 3 ) : invoice.total_patient_owes
    const chargeDetails = {
      ...( splitSinglePayment && { // If it's a split payment we need to add recurring details
        startDate: new Date().toISOString()
          .slice( 0, 10 ),
        intervalLength: 1,
        totalOccurrences: 3,
        initialPayment : true,
        name: `Initial PAP Equipment Payment`
      }),
      ...( invoice?.invoice_number && {
        invoiceNumber: invoice.invoice_number
      })
    }
    const upfrontChargeBody = buildChargeBody( amount, singlePaymentMethod, chargeDetails )

    return await chargeV2( upfrontChargeBody )
  }


  function handleRecurringPaymentCheck( event ) {
    const useSamePaymentMethod = event.target.checked
    setShouldUseSingleForRecurring( useSamePaymentMethod )
    setDisableSubmitPayment( !useSamePaymentMethod ) // is useSamePaymentMethod is unchecked, disable the submit button until a recurring payment method is selected
  }


  const isSinglePaymentMethodComplete = Boolean( singlePaymentMethod?.cardNumber || singlePaymentMethod?.accountNumber )
  const isRecurringPaymentMethodComplete = isSinglePaymentMethodComplete && ( Boolean( recurringPaymentMethod?.cardNumber || recurringPaymentMethod?.accountNumber ) || shouldUseSingleForRecurring )

  if ( !invoice ) return <AobLoader message={`Please hold tight we are looking up your payment information. This may take a few seconds. Thanks for your patience!`} />

  if ( paymentLoading ) return <AobLoader withLogo={false} message={`Please hold tight while we process your payment...`} />

  return (
    <div className="max-w-full mx-auto w-full md:w-10/12 lg:w-1/2 flex flex-col gap-10 md:gap-16 bg-[#F7F7F7]">
      <div className="bg-white flex flex-col gap-8 px-4 md:px-10 items-center">

        <HeadingWithBackButton
          className="mt-24"
          headingText="Checkout"
          headingClassName="sm:text-3xl md:text-[42px] sm:leading-9 lg:leading-[47px] font-inter tracking-wide font-semibold text-center mt-16 text-[#0057A8]"
          backText="Back"
          onBackClick={pageProps.prevPage}
          shouldHideBackButton={paymentLoading || paymentError || authnetError}
        />
        <>

          {paymentDisclaimerCopy?.PaymentDetails && (
            <>
              <paymentDisclaimerCopy.PaymentDetails />
            </>
          )}
        </>

        <div className="py-2 text-center w-full md:w-11/12">
          <UpfrontPaymentBlock
            splitSinglePayment={splitSinglePayment}
            setSplitSinglePayment={setSplitSinglePayment}
            invoice={invoice}
            singlePaymentMethod={singlePaymentMethod}
            setSinglePaymentMethod={setSinglePaymentMethod}
            setDisableSubmitPayment={setDisableSubmitPayment}
          />
          <RecurringPaymentBlock
            invoice={invoice}
            recurringPaymentMethod={recurringPaymentMethod}
            showUseSamePaymentCheck={isSinglePaymentMethodComplete}
            useSamePaymentCheck={shouldUseSingleForRecurring}
            onUseSamePaymentCheck={handleRecurringPaymentCheck}
            setRecurringPaymentMethod={setRecurringPaymentMethod}
            setDisableSubmitPayment={setDisableSubmitPayment}
          />
          <AddressBlock
            hideAddressConfirm
            headerText="Shipping Address"
            invoice={invoice}
          />
          <AobContactBlock
            invoice={invoice}
            header="Contact"
            contactBlockStyle="mt-9 mb-6 bg-[#F7F7F7] rounded-lg"
          />
          <ProcessPaymentButton
            onClick={submitPayment}
            disabled={paymentLoading || splitSinglePayment === null || !isSinglePaymentMethodComplete || !isRecurringPaymentMethodComplete || disableSubmitPayment || !isAddressComplete}
            paymentError={paymentError}
          />
        </div>
      </div>
    </div>
  )
}

Payments.propTypes = {
  invoice: PropTypes.shape({
    total_patient_owes: PropTypes.number,
    invoice_number: PropTypes.string,
    recurring_payment: PropTypes.number,
    single_payment: PropTypes.number,
    number_of_payments: PropTypes.number
  }),
  authnetError: PropTypes.string,
  allowPayment: PropTypes.bool,
  isPaymentBeingAdded: PropTypes.bool,
  setIsPaymentBeingAdded: PropTypes.func,
  paymentMethods: PropTypes.object,
  setPaymentMethods: PropTypes.func,
  setPaymentCompleted: PropTypes.func,
  removedItems: PropTypes.array,
  payLater: PropTypes.bool,
  internalPatientData: PropTypes.object
}

export default Payments
