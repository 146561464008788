import React from 'react'
import PropTypes from 'prop-types'
import { sleepMyEquipmentPlaceholder } from 'resources/images'

const Product = ({ product }) => {

  return (
    <div className="my-8">
      <div className="grid grid-rows-1 grid-cols-6 gap-4 mb-8">
        <div className="col-span-2 grid place-items-center">
          <img alt={product?.image?.label ?? `sleep-placeholder`} src={product?.image?.url ?? sleepMyEquipmentPlaceholder} className="max-h-32" />
        </div>

        <div className="col-span-4 py-4">
          <p className="text-sm">{product.description}</p>
        </div>
      </div>
    </div>
  )
}
Product.propTypes = {
  product: PropTypes.object,
  type: PropTypes.string
}

const Products = ({products}) => {

  return (
    <div>
      {products?.length
        ? products.map( product => {
          return <Product key={product.hcpc_id} product={product} />
        }) : <p className="my-8">{`No Products Found`}</p>
      }
    </div>
  )
}
Products.propTypes = {
  products: PropTypes.array
}

export default Products
