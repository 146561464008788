import { useEffect } from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import useRetrieveZipCode from './hooks/useRetrieveZipCode'
import DoctorDisplay from './components/DoctorDisplay'
import useRetrieveDoctor from './hooks/useRetrieveDoctor'
import DoctorInfo from './types/DoctorInfo'
import { useDoctorStore } from './hooks/state'
import { useTokenStore } from 'stores/tokenStore'
import { useSnlPatientHashStore } from 'stores/snlPatientHashStore'

type DoctorBlockProps = {
  onSuccess?: ( _doctor: DoctorInfo ) => void;
  onExistingDoctor?: () => void;
  onLoadingComplete?: () => void;
  forceUpdate?: boolean;
  containerStyle?: string;
  headerStyle?: string;
  isMyAccount?: boolean;
  scrollIntoView?: boolean;
  doctorSearchStyle?: string;
}

// used in:
// resupply controller - both new doctor required and new prescription required (these can be combined)
// sleep new leads controller
// account v2 physician block
function DoctorBlock({ onSuccess, forceUpdate = false, isMyAccount = false, headerStyle, containerStyle, onExistingDoctor, scrollIntoView = false, onLoadingComplete, doctorSearchStyle } : DoctorBlockProps ) : JSX.Element | null {
  const zipLoading = useRetrieveZipCode() // sets the zipcode for doctor search
  const doctorLoading = useRetrieveDoctor( onExistingDoctor ) // retrieves current doctor
  const { setDoctorInput } = useDoctorStore()

  useEffect( () => {
    setDoctorInput({
      zipCode: ``,
      range: 10,
      name: ``
    })
    if ( scrollIntoView ) {
      const doctorBlock = document.getElementById( `doctor-block` )
      if ( doctorBlock ) {
        doctorBlock.scrollIntoView({
          behavior: `smooth`
        })
      }
    }
  }, [] )

  const isLoading = zipLoading || doctorLoading

  useEffect( () => {
    if ( !isLoading && onLoadingComplete ) {
      onLoadingComplete()
    }
  }, [ isLoading, onLoadingComplete ] )

  const { customerToken } = useTokenStore()
  const { snlPatientHash } = useSnlPatientHashStore()

  if ( !customerToken && !snlPatientHash ) return null

  return (
    <div
      id="doctor-block"
      className={`my-5 flex flex-col items-center justify-center mx-auto gap-5 ${containerStyle}`}
    >
      <div className={`${ !isMyAccount && `mt-5 mb-10`} w-full mx-auto`}>
        {
          zipLoading || doctorLoading ?
            <LoadingSpinner />
            :
            <DoctorDisplay
              onSuccess={onSuccess}
              forceUpdate={forceUpdate}
              headerStyle={headerStyle}
              containerStyle={containerStyle}
              doctorSearchStyle={doctorSearchStyle}
              isMyAccount={isMyAccount}
            />
        }
      </div>
    </div>
  )
}

export default DoctorBlock