import { thumbsUpList, thumbsUpListLightBlue, decStarCheck, decStarCheckLightBlue, minimalistBox, minimalistBoxLightBlue } from "resources/images"

export const ThankYouDetailsFromAOB = [
  {
    "title": `Verifying Benefits`,
    "description": `An Aeroflow Sleep Specialist will work to verify your benefits and check which supplies you are eligible to receive through insurance.`,
    "imageAlt": `Blue Verify Check Mark`,
    "imageSrc": decStarCheck
  },
  {
    "title": `Documentation Requirements`,
    "description": `Health insurance companies require that Aeroflow Sleep obtain a prescription for your CPAP Supplies prior to shipping the order. We’ll be working with you and your doctor to obtain any required documentation.`,
    "imageAlt": `List with thumbs up`,
    "imageSrc": thumbsUpList
  },
  {
    "title": `Order Confirmed and Shipped`,
    "description": `Once all necessary documentation is received, an Aeroflow Sleep Specialist will confirm your final order with you and ship your new CPAP Supplies.`,
    "imageAlt": `Minimalist box`,
    "imageSrc": minimalistBox
  }
]

export const ThankYouDetailsFromSNL = [
  {
    "title": `Aeroflow Will Verify Your Benefits`,
    "imageAlt": `Blue Verify Check Mark`,
    "imageSrc": decStarCheckLightBlue
  },
  {
    "title": `Aeroflow Will Gather All Necessary Documentation`,
    "description": `Insurance requires that we obtain an Rx for your order prior to shipping.`,
    "additionalTitle": `If Your CPAP Prescription is Expired,`,
    "additionalDescription": `To receive your PAP supplies, your insurance mandates a prescription. 
    If you lack an updated prescription and a current doctor, consider [restASSURED](https://restassuredonline.com/). 
    They offer telehealth services connecting you with a licensed physician for a prescription that will be compliant with insurance. 
    Click below if interested in utilizing restASSURED for your prescription needs.`,
    "imageAlt": `List with thumbs up`,
    "imageSrc": thumbsUpListLightBlue,
    "button": {
      "text": `Get A New Prescription`,
      "link": `https://restassuredonline.com/referral/9ad98a55-ba8a-496b-96f3-8b8381fcdfcf`,
      "externalLink": true
    }
  },
  {
    "title": `Aeroflow will Confirm your Order and Ship!`,
    "imageAlt": `Minimalist box`,
    "imageSrc": minimalistBoxLightBlue
  }
]
