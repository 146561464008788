import React from 'react'
import { useSNLStore } from '../State/snlStorage'
import icoBlueCheckLg from 'resources/images/intermediate-svg/large-blue-checkmark.svg'
import { useNavigate } from 'react-router-dom'

interface EditSelectionProps {
  title: string
  optionLabel: string
  step: string
  path: string
  transparentBackground?: boolean
}

interface EditSelectionState {
  snlEditSelection: () => {
    label: string,
    value: string,
    step: string,
    path: string
  }[]
}

interface EditSelectionComponentProps {
  transparentBackground?: boolean;
  showSummary?: boolean;
}

export const EditSelectionItem = ({ title, optionLabel, path, transparentBackground}: EditSelectionProps ) => {
  const navigate = useNavigate()

  return (
    <div className="sm:mb-2 md:mb-[18px]">
      <div
        className={`flex items-stretch rounded-2xl overflow-hidden ${transparentBackground ? `` : `bg-[#fff]`}`}
        style={{
          boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.25)`
        }}
      >
        <div className="bg-[#BBEBFC] basis-[57px] shrink-0 flex items-center justify-center">
          <img src={icoBlueCheckLg} alt="blue check" />
        </div>
        <div className="flex flex-col p-3">
          <p className="font-inter">{title}</p>
          <div>
            <div className="flex text-deepSleepBlue">
              <p>{`${optionLabel}`}</p>
              <p className="px-1">{`-`}</p>
              <p className="underline cursor-pointer" onClick={() => navigate( path )}>{`Edit`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const EditSelection = ({ transparentBackground, showSummary }: EditSelectionComponentProps ) => {
  const { snlEditSelection } = useSNLStore() as EditSelectionState
  const selection = snlEditSelection()

  return (
    <div>
      {selection.length > 0 && selection
        .filter( item => !showSummary || [ `cpapMaskModel`, `cpapMachineModel` ].includes( item.step ) ) // only show cpapMaskModel and cpapMachineModel on summary page
        .map( ( item ) => (
          <EditSelectionItem
            key={item.label}
            title={item.label}
            optionLabel={item.value}
            step={item.step}
            path={item.path}
            transparentBackground={transparentBackground}
          />
        ) )}
    </div>
  )
}