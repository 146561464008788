import React, { useMemo, useEffect } from 'react'
import { StyledErrorWrapper } from 'components/layoutUi'
import LoadingSpinner from 'components/LoadingSpinner'
import { ModelSelector } from 'components/SNLSelectors'
import { machineImages } from 'modules/sleepNewLeads/constants'
import groupBy from 'lodash/groupBy'
import { EditSelection } from '../EditSelection/EditSelection'
import { useMediaQuery } from 'react-responsive'
import { PageTracker } from '..'
import ActionLink from 'components/SNLSelectors/ActionLink'
import { SNL_MASK_TYPE_PATH, maskStepsUrlMap, SNL_MACHINE_BRAND_PATH } from '../constants'
import InsuranceAdvantage from '../InsuranceAdvantage/InsuranceAdvantage'
import { useNavigate } from 'react-router-dom'
import { INSURANCE_ADVANTAGE_COPY } from '../InsuranceAdvantage/constants'
import { useGetMachineData } from '../hooks/useGetMachineData'

const groupedMachineImages = groupBy( machineImages, `brand` )

interface MachineName {
  name: string;
}

const MachineModel = ({ formValues, onHandleChange, ...pageProps }: any ) => {
  const navigate = useNavigate()
  const { machineData, isLoading, errorMessage } = useGetMachineData()

  useEffect( () => {
    if ( !formValues.cpapMachineBrand ) return navigate( SNL_MACHINE_BRAND_PATH )
  }, [ ] )

  // if the mask data is present but incomplete, get the path for the first missing step
  const checkMissingSteps = ( ) => {
    const steps = [ `cpapMaskType`, `cpapMaskBrand`, `cpapMaskModel` ]
    const existingSteps = steps.filter( step => formValues[step] )

    if ( existingSteps.length > 0 && existingSteps.length < steps.length ) {
      const firstMissingStep = steps.find( step => !formValues[step] )
      return maskStepsUrlMap[firstMissingStep as keyof typeof maskStepsUrlMap]
    }
    return null
  }

  const handleNext = () => {
    const missingStepPath = checkMissingSteps()
    return missingStepPath ? navigate( missingStepPath ) : pageProps.nextPage()
  }

  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  const machines = useMemo( () => {
    if ( !formValues.cpapMachineBrand ) return []

    const machineNames: MachineName[] = machineData.find( entry =>
      entry.brand === formValues.cpapMachineBrand
    )?.machineNames || []

    const machineMap = Object.fromEntries(
      machineNames.map( item => [ item.name, item ] )
    )

    // use the order from the machineImages object
    return groupedMachineImages[formValues.cpapMachineBrand]
      ?.filter( mItem => machineMap[mItem.machine] )
      .map( mItem => ({
        ...machineMap[mItem.machine],
        imgSrc: mItem.imgSrc
      }) ) || []

  }, [ formValues.cpapMachineBrand, machineData ] )

  const handleChange = ( e: any ) => {
    if ( e.target.name === `cpapMachineModel` ){
      const { cpapMachineDmeID, cpapMachineModel, lastOrderCpapMachine } = e.target.value
      onHandleChange({
        ...formValues,
        cpapMachineDmeID,
        cpapMachineModel,
        lastOrderCpapMachine
      })
      handleNext()
    } else {
      onHandleChange({
        ...formValues
      })

    }
  }

  const handleChangeMask = () => {
    pageProps.setPage( SNL_MASK_TYPE_PATH )
  }

  if ( isLoading ) {
    return (
      <div>
        <PageTracker title={`Your Machine`} />
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div>
      <PageTracker
        title={`Tell us about your machine`}
        description={`To give you the right filters, tubing and water chamber, let us know what machine you’re using`}
      />

      {
        isMobile && <EditSelection />
      }

      <ModelSelector
        heading={`Select your current machine model`}
        name="cpapMachineModel"
        machines={machines}
        selectedValue={formValues.cpapMachineDmeID}
        onChange={handleChange}
        showOnMobile
      />

      {
        isMobile &&
        <InsuranceAdvantage
          className="mt-14"
          description={INSURANCE_ADVANTAGE_COPY.machineDescription}
        />
      }

      {formValues?.cpapMaskDmeID &&
        ( <ActionLink
          handleClick={handleChangeMask}
          text={`Change Your Mask`}
        />
        )}

      <StyledErrorWrapper>
        {errorMessage}
      </StyledErrorWrapper>
    </div>
  )
}

export default MachineModel