import HeaderBar from './HeaderBar'
import AlertBar from 'components/alertBar'
import MegaMenu from 'components/megaMenuV2'
import { SleepLinearGradient } from 'components/sleepLinearGradient'

import {
  DIGITAL_ABN_PATH,
  EMBED_QUALIFY_FORM_PATH,
  LOGIN_LEGACY_PATH,
  LOGIN_M2_PATH,
  LOGIN_PATH,
  MAINTENANCE_PAGE_PATH,
  MAINTENANCE_PAGE_THANK_YOU_PATH,
  SNL_ALL_PAGES_PATH,
  SNL_BASE
} from 'routes'
import { PAYMENT_PORTAL_PATH } from 'modules/aobPayments/constants'
import { RESUPPLY_PORTAL_PATH } from 'modules/resupply2'
import MinimalHeaderBar from './MinimalHeaderBar'
import { whichPathsMatch } from 'components/auth/routeUtils'
import { fetchSnowdogMenus } from '../megaMenuV2/fetchSnowdogMenus'

const Header = () => {
  // fetch snowdog menus for menu display
  fetchSnowdogMenus()
  const noHeaderMatches = whichPathsMatch( [ DIGITAL_ABN_PATH, MAINTENANCE_PAGE_PATH, MAINTENANCE_PAGE_THANK_YOU_PATH, PAYMENT_PORTAL_PATH, EMBED_QUALIFY_FORM_PATH, SNL_BASE, SNL_ALL_PAGES_PATH ] )
  const minimalHeaderMatches = whichPathsMatch( [ LOGIN_PATH, LOGIN_M2_PATH, LOGIN_LEGACY_PATH, RESUPPLY_PORTAL_PATH ] )

  if ( noHeaderMatches.length ) return null

  if ( minimalHeaderMatches.length ) {
    return (
      <header className="bg-white">
        <MinimalHeaderBar showAccountLogout={Boolean( minimalHeaderMatches.find( path => { return path === RESUPPLY_PORTAL_PATH }) )} showAccountMenu={false} />
      </header>
    )
  }

  return (
    <header className="bg-white">
      <SleepLinearGradient height={34} />
      <AlertBar />
      <HeaderBar />
      <MegaMenu />
    </header>
  )
}

export default Header